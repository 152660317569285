import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Link, Loading, Portal } from "../../components";
import AuthorizedImage from "../../components/AuthorizedImage";
import ComplianceCategory from "../../components/ComplianceCategory";
import Table, { ITableColumn } from "../../components/Table";
import Toast from "../../components/Toast";
import config from "../../config";
import { useToast } from "../../hooks";
import useDownloadCsv from "../../hooks/useDownloadCsv";
import useQueryString from "../../hooks/useQueryString";
import {
    IComplianceType,
    IUnableToAccess,
    useUnableToAccessList,
} from "../../utils/api/jobs";

const AssetUnableToAccessList = () => {
    const { t } = useTranslation();
    const unableToAccess = useUnableToAccessList({
        sortProperty: "date",
        sortDirection: "desc",
        filters: {
            assetId: {
                readOnly: true,
                operator: "{AND}",
                filters: [{ function: "!=", value: "null" }],
            },
        },
    });

    const { getQueryString } = useQueryString(unableToAccess.search);

    const columns = useMemo(() => {
        const renderImage = (value: string, row: IUnableToAccess) => (
            <Link url={`/issues/asset-uta/${row.id}`}>
                <AuthorizedImage image={value} circle={true} size={40} />
            </Link>
        );

        const renderAppliance = (value: unknown, row: IUnableToAccess) => (
            <>
                {row.appliance && (
                    <Link url={`/management/appliances/${row.appliance.id}`}>
                        {row.appliance.type.displayName}:&nbsp;
                        {row.appliance.displayName}
                    </Link>
                )}
            </>
        );

        const renderAddress = (value: string, row: IUnableToAccess) => (
            <Link url={`/management/properties/${row.property.id}`}>
                {row.property.addressString}
            </Link>
        );

        const renderEngineer = (value: string, row: IUnableToAccess) => (
            <Link url={`/management/engineers/${row.engineer.id}`}>
                {row.engineer.name}
            </Link>
        );

        const renderComplianceType = (value: IComplianceType | null) => {
            return (
                <>
                    {value && (
                        <ComplianceCategory
                            colour={value.colour}
                            displayName={t(value.displayName)}
                        />
                    )}
                </>
            );
        };

        const col: { [key: string]: ITableColumn<IUnableToAccess> } = {
            imageUrl: {
                title: t("Image"),
                sortable: false,
                filterable: false,
                render: renderImage,
            },
            date: {
                title: t("Date"),
                type: "date",
            },
            appliance: {
                title: t("Asset"),
                filterable: false,
                sortable: false,
                render: renderAppliance,
            },
            "property.addressString": {
                title: t("Property"),
                filterable: false,
                canBeToggledByUser: false,
                render: renderAddress,
            },
            "engineer.name": {
                title: t("Engineer"),
                render: renderEngineer,
            },
            comments: {
                title: t("Comments"),
                filterable: false,
                sortable: false,
            },
            jobType: {
                title: t("Job Type"),
            },
            complianceType: {
                title: t("Compliance Category"),
                render: renderComplianceType,
            },
            utaType: {
                title: t("UTA Type"),
            },
        };

        return col;
    }, [t]);

    const toast = useToast();

    const { handleDownloadClick } = useDownloadCsv({
        exportDataUrl: `${config.jobsApiUrl}/unabletoaccess/export`,
        filters: unableToAccess.filters,
        search: getQueryString(),
        getUrl: false,
        showToast: toast.show,
        csvReportName: "Asset utas",
    });

    return (
        <>
            <Card title={t("Unable To Access Reports")}>
                {unableToAccess.loaded ? (
                    <Table
                        preferences="asset-unable-to-access-table"
                        columns={columns}
                        {...unableToAccess}
                        alternateCsvFunction={handleDownloadClick}
                        hideChildComponent={toast.visible}
                    />
                ) : (
                    <Loading />
                )}
            </Card>

            {toast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    );
};

export default AssetUnableToAccessList;
