import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IJobsTableProps } from ".";
import {
    ColourPill,
    Icon,
    JobPaperworkDropdown,
    JobType,
    Link,
    Loading,
    Portal,
    PropertyCategoriesContext,
} from "../../../components";
import Dropdown, { IDropdownItem } from "../../../components/Dropdown";
import Table, { IBulkAction, ITableColumn } from "../../../components/Table";
import Toast from "../../../components/Toast";
import config from "../../../config";
import { useToast } from "../../../hooks";
import useDownloadCsv from "../../../hooks/useDownloadCsv";
import useQueryString from "../../../hooks/useQueryString";
import {
    IComplianceType,
    ISimpleJob,
    ISimplePaperwork,
    useJobs,
} from "../../../utils/api/jobs";
import { useDocumentsDownload } from "../../../utils/api/lgsrs";
import {
    defaultDate,
    getDifferenceInSeconds,
    secondsToTimeSpan,
    toTimeString,
} from "../../../utils/dates";
import downloadFile from "../../../utils/files";

const JobsTable = ({ rootJobs, csvReportName }: IJobsTableProps) => {
    const { t } = useTranslation();
    const jobs = useJobs({
        sortProperty: "date",
        sortDirection: "desc",
        rootJobs,
    });

    const { download } = useDocumentsDownload();
    const { selectedCategories } = useContext(PropertyCategoriesContext);

    const downloadLgsrs = useCallback(
        (ids: number[]) => {
            const paperworkIds = jobs.records
                .filter((j) => ids.includes(j.id))
                .flatMap((j) =>
                    j.paperwork
                        .filter((p) => p.documentName === "LGSR")
                        .map((p) => p.id),
                );

            download(paperworkIds).subscribe((url) => {
                downloadFile(url);
            });
        },
        [download, jobs.records],
    );

    const downloadDocuments = useCallback(
        (ids: number[]) => {
            const paperworkIds = jobs.records
                .filter((j) => ids.includes(j.id))
                .flatMap((j) => j.paperwork.map((p) => p.id));

            download(paperworkIds).subscribe((url) => {
                downloadFile(url);
            });
        },
        [download, jobs.records],
    );

    const toast = useToast();

    const { getQueryString } = useQueryString(jobs.search);
    const { handleDownloadClick } = useDownloadCsv({
        exportDataUrl: `${config.jobsApiUrl}/export`,
        filters: jobs.filters,
        filterColumns: (vc) => vc.key !== "actions",
        search: getQueryString(),
        getUrl: false,
        showToast: toast.show,
        csvReportName,
    });

    const columns = useMemo(() => {
        const renderId = (value: number) => (
            <Link url={`/jobs/jobs/${value}`}>#{value}</Link>
        );

        const renderParentJobId = (value: number | null) =>
            value !== null && <Link url={`/jobs/jobs/${value}`}>#{value}</Link>;

        const renderComplianceType = (value: IComplianceType | null) => {
            return (
                value && (
                    <ColourPill
                        customColour={value.colour}
                        value={t(value.displayName)}
                    />
                )
            );
        };

        const renderJobType = (value: string) => <JobType jobType={value} />;

        const renderEngineer = (value: string, row: ISimpleJob) => (
            <Link url={`/management/engineers/${row.engineer.id}`}>
                {value}
            </Link>
        );

        const renderAddressString = (value: string, row: ISimpleJob) => (
            <Link url={`/management/properties/${row.property.id}`}>
                {value}
            </Link>
        );

        const renderPaperwork = (value: ISimplePaperwork[]) =>
            value &&
            value.length > 0 && <JobPaperworkDropdown paperwork={value} />;

        const col: { [key: string]: ITableColumn<ISimpleJob> } = {
            id: {
                title: t("Id"),
                filterable: false,
                canBeToggledByUser: false,
                render: renderId,
            },
            parentJobId: {
                title: t("Parent Job Id"),
                filterable: false,
                canBeToggledByUser: false,
                hidden: rootJobs,
                render: renderParentJobId,
            },
            externalJobReference: {
                title: t("External Reference Number"),
                filterable: false,
            },
            complianceType: {
                title: t("Compliance Category"),
                render: renderComplianceType,
            },
            jobType: {
                title: t("Job Type"),
                render: renderJobType,
            },
            date: {
                title: t("Job Date"),
                type: "date",
            },
            startTime: {
                field: "date",
                title: t("Start Time"),
                type: "time",
                filterable: false,
                sortable: false,
            },
            endDate: {
                title: t("End Time"),
                filterable: false,
                sortable: false,
                render: (value: string) => {
                    if (value !== defaultDate) {
                        return toTimeString(new Date(value));
                    }
                },
            },
            timeTaken: {
                title: t("Time Taken"),
                filterable: false,
                sortable: false,
                render: (value, row) => {
                    if (row.endDate !== defaultDate) {
                        return secondsToTimeSpan(
                            getDifferenceInSeconds(
                                new Date(row.date),
                                new Date(row.endDate),
                            ),
                        );
                    }
                },
            },
            "engineer.name": {
                title: t("Engineer"),
                render: renderEngineer,
            },
            contractor: {
                title: t("Contractor"),
            },
            "property.addressString": {
                title: t("Property"),
                render: renderAddressString,
            },
            distanceBetween: {
                title: t("Distance Between"),
                type: "number",
                render: (value) => `${value}km`,
            },
            totalTasks: {
                title: t("Task Count"),
                type: "number",
            },
            totalIssues: {
                title: t("Issues Count"),
                type: "number",
            },
            paperwork: {
                title: t("Paperwork"),
                sortable: false,
                filterable: false,
                render: renderPaperwork,
            },
            auditStatus: {
                title: t("Audit Status"),
            },
            slaHit: {
                title: t("SLA Hit"),
                render: (value: boolean | null) => {
                    if (value !== null) {
                        return value ? t("Achieved") : t("Not achieved");
                    }
                },
            },
            uprn: {
                field: "uprn",
                title: t("UPRN"),
                filterable: false,
            },
            onsiteAuditCarriedOut: {
                title: t("Onsite Audit Carried Out"),
                render: (value, row) => (row.onsiteAudit ? "Yes" : "No"),
            },
            onsiteAudit: {
                title: t("Onsite Audit Date"),
                type: "date",
            },
            totalObservations: {
                title: t("Observations Count"),
                type: "number",
                hidden:
                    selectedCategories.length > 0 &&
                    !selectedCategories.some((c) => c.id === 21),
            },
            "eicr.paperworkStatus.status": {
                title: t("EICR Status"),
                type: "string",
                hidden:
                    selectedCategories.length > 0 &&
                    !selectedCategories.some((c) => c.id === 21),
            },
            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: (value, row) => {
                    if (row.paperwork.length > 0) {
                        const items: IDropdownItem[] = [
                            {
                                label: t("Download Documents"),
                                onClick: () => downloadDocuments([row.id]),
                            },
                        ];

                        if (
                            row.paperwork.some((p) => p.documentName === "LGSR")
                        ) {
                            items.push({
                                label: t("Download LGSRs"),
                                onClick: () => downloadLgsrs([row.id]),
                            });
                        }

                        return (
                            <Dropdown
                                label={
                                    <Icon
                                        icon="more"
                                        ariaLabel={t("Actions")}
                                    />
                                }
                                size="small"
                                items={items}
                            />
                        );
                    }
                },
            },
        };

        return col;
    }, [downloadDocuments, downloadLgsrs, rootJobs, selectedCategories, t]);

    const bulkActions = useMemo<IBulkAction[]>(
        () => [
            {
                value: "lgsrs",
                label: t("Download LGSRs"),
                onSubmit: downloadLgsrs,
            },
            {
                value: "documents",
                label: t("Download Documents"),
                onSubmit: downloadDocuments,
            },
        ],
        [downloadDocuments, downloadLgsrs, t],
    );

    return (
        <>
            {jobs.loaded ? (
                <Table
                    preferences="jobs-table"
                    columns={columns}
                    bulkActions={bulkActions}
                    {...jobs}
                    alternateCsvFunction={handleDownloadClick}
                    hideChildComponent={toast.visible}
                />
            ) : (
                <Loading />
            )}

            {toast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    );
};

export default JobsTable;
