import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, TabGroup, TabGroupTab } from "../../components";
import useActiveTabFromUrl from "../../hooks/useActiveTabFromUrl";
import { JobCsvReportName } from "../../utils/api/jobs";
import JobsTable from "./JobsTable";

const tabs: {
    name: string;
    csvReportName: JobCsvReportName;
}[] = [
    { name: "Jobs", csvReportName: "Jobs" },
    { name: "Sub-jobs", csvReportName: "Sub jobs" },
];

const Jobs = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useActiveTabFromUrl(
        tabs.map((tab) => tab.name),
    );

    const tabGroups = useMemo(
        () =>
            tabs.map((tab) => ({
                key: tab.name,
                header: t(tab.name.replace(/-/g, " ")),
                csvReportName: tab.csvReportName,
            })),
        [t],
    );

    return (
        <Card title={t("Jobs")} padding={false}>
            <TabGroup currentTab={activeTab} onActivate={setActiveTab}>
                {tabGroups.map((tab) => (
                    <TabGroupTab header={tab.header} key={tab.key}>
                        <JobsTable
                            rootJobs={tab.key === "Jobs"}
                            csvReportName={tab.csvReportName}
                        />
                    </TabGroupTab>
                ))}
            </TabGroup>
        </Card>
    );
};

export default Jobs;
