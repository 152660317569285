import { useTranslation } from "react-i18next";
import { Card, TabGroup, TabGroupTab } from "../../components";
import { useManualUploadResetApprovalInboxNumbers } from "../../utils/api/jobs";
import ServiceDateResetApprovalTable from "./ServiceDateResetApprovalTable";

const ServiceDateResetApproval = () => {
    const { t } = useTranslation();
    const inboxNumbers = useManualUploadResetApprovalInboxNumbers();

    return (
        <Card
            title={t("Manual upload - Service date reset approval")}
            padding={false}
        >
            <TabGroup>
                <TabGroupTab
                    header={
                        <>
                            {t("Waiting")}&nbsp;
                            {inboxNumbers.loaded &&
                                inboxNumbers.value.waitingApproval > 0 &&
                                `(${inboxNumbers.value.waitingApproval})`}
                        </>
                    }
                >
                    <ServiceDateResetApprovalTable
                        type="waiting"
                        csvReportName="Service date reset approvals waiting"
                    />
                </TabGroupTab>
                <TabGroupTab header={t("Lapsed")}>
                    <ServiceDateResetApprovalTable
                        type="lapsed"
                        csvReportName="Service date reset approvals lasped"
                    />
                </TabGroupTab>
                <TabGroupTab header={t("Approved")}>
                    <ServiceDateResetApprovalTable
                        type="approved"
                        csvReportName="Service date reset approvals approved"
                    />
                </TabGroupTab>
                <TabGroupTab header={t("Declined")}>
                    <ServiceDateResetApprovalTable
                        type="declined"
                        csvReportName="Service date reset approvals declined"
                    />
                </TabGroupTab>
            </TabGroup>
        </Card>
    );
};

export default ServiceDateResetApproval;
