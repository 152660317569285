import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Card,
    Dropdown,
    Icon,
    JobPaperworkDropdown,
    Link,
    Loading,
    Portal,
    Table,
} from "../../components";
import DeleteManualUploadModal from "../../components/DeleteManualUploadModal";
import { IDropdownItem } from "../../components/Dropdown";
import NavigationModal from "../../components/NavigationModal";
import { ITableColumn } from "../../components/Table";
import Toast from "../../components/Toast";
import config from "../../config";
import {
    useHasPermission,
    useHasUserParentPermissionFunction,
    useToast,
    useToggle,
} from "../../hooks";
import useDownloadCsv from "../../hooks/useDownloadCsv";
import useQueryString from "../../hooks/useQueryString";
import {
    ISimpleJob,
    ISimplePaperwork,
    useRecoveryJobs,
} from "../../utils/api/jobs";
import { clearCache } from "../../utils/cache";

const ManualUploads = () => {
    const recoveryJobs = useRecoveryJobs({
        sortProperty: "date",
        sortDirection: "desc",
    });
    const { refresh, loaded, filters, search } = recoveryJobs;

    const { t } = useTranslation();

    const { visible, hide, show } = useToggle();

    const { getQueryString } = useQueryString(search);

    const toast = useToast();

    const {
        show: showDeleteManualUploadModal,
        visible: isDeleteManualUploadModalVisible,
        hide: hideDeleteManualUploadModal,
    } = useToggle();

    const completeManualUploadDeletion = useCallback(() => {
        hideDeleteManualUploadModal();

        clearCache();
        refresh();
    }, [hideDeleteManualUploadModal, refresh]);

    const canDeleteManualUpload = useHasPermission("portal_admin");
    const hasRequiredPermission = useHasUserParentPermissionFunction();

    const [jobId, setJobId] = useState<number>();

    const columns = useMemo(() => {
        const renderId = (value: string, row: ISimpleJob) => (
            <Link url={`/jobs/jobs/${row.id}`}>#{value}</Link>
        );

        const renderEngineer = (value: string, row: ISimpleJob) => (
            <Link url={`/management/engineers/${row.engineer.id}`}>
                {value}
            </Link>
        );

        const renderAddressString = (value: string, row: ISimpleJob) => (
            <Link url={`/management/properties/${row.property.id}`}>
                {value}
            </Link>
        );

        const renderPaperwork = (value: ISimplePaperwork[]) =>
            value && value.length > 0 ? (
                <JobPaperworkDropdown paperwork={value} />
            ) : (
                t("Awaiting Paperwork")
            );

        const cols: { [key: string]: ITableColumn<ISimpleJob> } = {
            id: {
                title: t("Id"),
                render: renderId,
                filterable: false,
            },
            createdAt: {
                title: t("Uploaded Date"),
                type: "date",
            },
            date: {
                title: t("Date"),
                type: "date",
            },
            jobType: {
                title: t("Job Type"),
            },
            appliancesMadeSafe: {
                title: t("Assets Made Safe"),
                type: "number",
            },
            "engineer.name": {
                title: t("Engineer"),
                render: renderEngineer,
            },
            "property.addressString": {
                title: t("Address"),
                render: renderAddressString,
            },
            uprn: {
                title: t("UPRN"),
                filterable: false,
            },
            uploadedBy: {
                title: t("Uploaded By"),
            },
            forceServiceDateReset: {
                title: t("Date Reset"),
                render: (value: boolean | null) => {
                    if (value !== null) {
                        return value ? t("Yes") : t("No");
                    }
                },
            },
            firstPaperworkType: {
                title: t("Paperwork Type"),
            },
            paperwork: {
                title: t("Paperwork"),
                render: renderPaperwork,
            },
            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: (value, row) => {
                    if (
                        hasRequiredPermission(
                            "portal_admin",
                            row.landlord.userId,
                        )
                    ) {
                        const items: IDropdownItem[] = [
                            {
                                label: t("Delete Manual Entry"),
                                onClick: () => {
                                    setJobId(row.id);
                                    showDeleteManualUploadModal();
                                },
                            },
                        ];
                        return (
                            <Dropdown
                                label={
                                    <Icon
                                        icon="more"
                                        ariaLabel={t("Actions")}
                                    />
                                }
                                size="small"
                                items={items}
                            />
                        );
                    }
                },
            },
        };
        return cols;
    }, [hasRequiredPermission, showDeleteManualUploadModal, t]);

    const downloadCsv = useDownloadCsv({
        exportDataUrl: `${config.jobsApiUrl}/export`,
        filters,
        filterColumns: (vc) => vc.key !== "actions",
        search: getQueryString(),
        getUrl: false,
        showToast: toast.show,
        csvReportName: "Manual uploads",
    });

    return (
        <>
            <Card
                title={t("Manual Uploads")}
                actions={
                    <Button onClick={show}>{t("Add a manual upload")}</Button>
                }
            >
                {loaded ? (
                    <Table
                        columns={columns}
                        {...recoveryJobs}
                        preferences="manual-uploads-table"
                        alternateCsvFunction={downloadCsv.handleDownloadClick}
                        hideChildComponent={toast.visible}
                    />
                ) : (
                    <Loading />
                )}
            </Card>

            {visible && (
                <NavigationModal
                    hide={hide}
                    title={t("Manual upload")}
                    message={t("Upload a job or record a UTA")}
                    primaryLinks={[
                        {
                            title: t("Upload a job"),
                            url: "/jobs/manual-uploads/create",
                            icon: "manual-upload",
                        },
                        {
                            title: t("Record a UTA"),
                            url: "/jobs/manual-uploads/create-uta",
                            icon: "manual-upload-uta",
                        },
                    ]}
                    description={t(
                        "You can upload documents directly to a job or property. Search for the job " +
                            "number or property name and visit the page to use the upload document button.",
                    )}
                />
            )}
            {canDeleteManualUpload &&
                isDeleteManualUploadModalVisible &&
                jobId !== undefined && (
                    <DeleteManualUploadModal
                        hide={completeManualUploadDeletion}
                        jobId={jobId}
                    />
                )}

            {toast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    );
};

export default ManualUploads;
