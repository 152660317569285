import { useSegment } from "@newfront/react-segment-hooks";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Link, Loading, Portal, Table } from "../../components";
import AuthorizedImage from "../../components/AuthorizedImage";
import ComplianceCategory from "../../components/ComplianceCategory";
import { ITableColumn } from "../../components/Table";
import Toast from "../../components/Toast";
import config from "../../config";
import { useToast } from "../../hooks";
import useDownloadCsv from "../../hooks/useDownloadCsv";
import useQueryString from "../../hooks/useQueryString";
import residentManager from "../../images/banners/resident-manager.svg";
import {
    IComplianceType,
    IUnableToAccess,
    useUnableToAccessList,
} from "../../utils/api/jobs";
import styles from "./UnableToAccess.module.scss";

const UnableToAccess = () => {
    const { t } = useTranslation();
    const unableToAccess = useUnableToAccessList({
        sortProperty: "date",
        sortDirection: "desc",
        filters: {
            assetId: {
                readOnly: true,
                operator: "{AND}",
                filters: [{ function: "=", value: "null" }],
            },
        },
    });

    const toast = useToast();

    const { getQueryString } = useQueryString(unableToAccess.search);
    const { handleDownloadClick } = useDownloadCsv({
        exportDataUrl: `${config.jobsApiUrl}/unabletoaccess/export`,
        filters: unableToAccess.filters,
        search: getQueryString(),
        getUrl: false,
        showToast: toast.show,
        csvReportName: "Property utas",
    });

    const columns = useMemo(() => {
        const renderImage = (value: string, row: IUnableToAccess) => (
            <Link url={`/issues/unabletoaccess/${row.id}`}>
                <AuthorizedImage image={value} circle={true} size={40} />
            </Link>
        );

        const renderAddressString = (value: string, row: IUnableToAccess) => (
            <Link url={`/management/properties/${row.property.id}`}>
                {row.property.addressString}
            </Link>
        );

        const renderEngineer = (value: unknown, row: IUnableToAccess) => (
            <Link url={`/management/engineers/${row.engineer.id}`}>
                {row.engineer.name}
            </Link>
        );

        const renderComplianceType = (value: IComplianceType | null) => {
            return (
                value && (
                    <ComplianceCategory
                        colour={value.colour}
                        displayName={t(value.displayName)}
                    />
                )
            );
        };

        const col: { [key: string]: ITableColumn<IUnableToAccess> } = {
            imageUrl: {
                title: t("Image"),
                sortable: false,
                filterable: false,
                render: renderImage,
            },
            date: {
                title: t("Date"),
                type: "date",
            },
            "property.addressString": {
                title: t("Property"),
                filterable: false,
                canBeToggledByUser: false,
                render: renderAddressString,
            },
            "engineer.name": {
                title: t("Engineer"),
                render: renderEngineer,
            },
            comments: {
                title: t("Comments"),
                filterable: false,
            },
            jobType: {
                title: t("Job Type"),
            },
            complianceType: {
                title: t("Compliance Category"),
                render: renderComplianceType,
            },
            utaType: {
                title: t("UTA Type"),
            },
        };

        return col;
    }, [t]);

    const analytics = useSegment();

    const handleBannerClick = useCallback(() => {
        if (config.isPageTrackingEnabled) {
            analytics.track({
                event: "Clicked Banner Advert",
                properties: {
                    advert_title: "Resident Manager",
                    advert_description:
                        "Sign up for our Resident Manager product...",
                    advert_type: "XTag Resident Manager banner",
                },
            });
        }
    }, [analytics]);

    return (
        <>
            <a
                href="https://www.plentific.com/en-gb/resident-manager"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.bannerLink}
                onClick={handleBannerClick}
            >
                <img
                    src={residentManager}
                    className={styles.bannerImage}
                    alt={t(
                        "Enable your residents to request repairs 24/7 and to receive updates on the progress",
                    )}
                />
            </a>

            <Card title={t("Unable To Access Reports")}>
                {unableToAccess.loaded ? (
                    <Table
                        preferences="unable-to-access-table"
                        columns={columns}
                        {...unableToAccess}
                        alternateCsvFunction={handleDownloadClick}
                        hideChildComponent={toast.visible}
                    />
                ) : (
                    <Loading />
                )}
            </Card>

            {toast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    );
};

export default UnableToAccess;
